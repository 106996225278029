<template>
  <div
    class="input-group"
    :class="`input-group-${variant} ${getStatus(error, success)} ${filled}`"
  >
    <label :class="variant === 'static' ? '' : 'form-label'">{{ label }}</label>
    <textarea
      :id="id"
      v-model="modelValueHandler"
      class="form-control"
      :rows="rows"
      :name="name"
      :placeholder="placeholder"
      :required="isRequired"
      :disabled="disabled"
      @keyup="onKeyupMaterialHook"
      @focus="onFocusMaterialHook"
      @focusout="onFocusoutMaterialHook"
    ></textarea>
  </div>
</template>

<script>
import { createVModelHandlers } from '@/helpers/vue';

export default {
  name: "MaterialTextarea",
  props: {
    variant: {
      type: String,
      default: "outline",
    },
    label: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    isRequired: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 5,
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'update:modelValue',
  ],

  computed: {
    ...createVModelHandlers(
      'modelValue',
    ),

    filled() {
      return !this.error && this.modelValueHandler !== ""
        ? 'is-filled'
        : '';
    }
  },

  methods: {
    getStatus: (error, success) => {
      let isValidValue;

      if (success) {
        isValidValue = "is-valid";
      } else if (error) {
        isValidValue = "is-invalid";
      } else {
        isValidValue = null;
      }

      return isValidValue;
    },

    onFocusMaterialHook() {
      this.$el.classList.add('is-focused');
    },
    onFocusoutMaterialHook() {
      this.$el.classList.remove('is-focused');
    },
  },
};
</script>
